import React, { useState, useEffect } from 'react';
import { Select, MenuItem, Box, SelectChangeEvent } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { subDays } from 'date-fns';
import calendarIcon from '../../assets/calendar.svg';

const PRESET_TYPES = [
  'today',
  'last7days',
  'last30days',
  'last3months',
  'last6months',
  'monthToDate',
  'yearToDate'
] as const;
export type PresetType = (typeof PRESET_TYPES)[number];
export function isPresetType(value: any): value is PresetType {
  return PRESET_TYPES.includes(value);
}

const rangeLabels: Record<PresetType, string> = {
  today: 'Today',
  last7days: 'Last 7 Days',
  last30days: 'Last 30 Days',
  last3months: 'Last 3 Months',
  last6months: 'Last 6 Months',
  monthToDate: 'Month to Date',
  yearToDate: 'Year to Date'
};

type DateRange = {
  startDate: Date;
  endDate: Date;
};

interface DateRangeSelectorProps {
  setRange: (range: DateRange) => void;
}

const DateRangeSelector: React.FC<DateRangeSelectorProps> = ({ setRange }) => {
  const [selectedRange, setSelectedRange] = useState<PresetType>('last7days');

  useEffect(() => {
    const currentDate = new Date();
    setRange({ startDate: subDays(currentDate, 6), endDate: currentDate });
  }, [setRange]);

  // Handle when user selects a new range preset
  const handlePresetChange = (event: SelectChangeEvent<PresetType>) => {
    const preset = event.target.value;
    if (!isPresetType(preset)) {
      return; // bad input
    }
    setSelectedRange(preset); // Set the selected range to update dropdown label

    const currentDate = new Date();

    switch (preset) {
      case 'today':
        setRange({ startDate: currentDate, endDate: currentDate });
        break;
      case 'last7days':
        setRange({ startDate: subDays(currentDate, 6), endDate: currentDate });
        break;
      case 'last30days':
        setRange({ startDate: subDays(currentDate, 29), endDate: currentDate });
        break;
      case 'last3months':
        setRange({ startDate: subDays(currentDate, 90), endDate: currentDate });
        break;
      case 'last6months':
        setRange({
          startDate: subDays(currentDate, 180),
          endDate: currentDate
        });
        break;
      case 'monthToDate':
        setRange({
          startDate: new Date(
            currentDate.getFullYear(),
            currentDate.getMonth(),
            1
          ),
          endDate: currentDate
        });
        break;
      case 'yearToDate':
        setRange({
          startDate: new Date(currentDate.getFullYear(), 0, 1),
          endDate: currentDate
        });
        break;
      // TO DO: Custom date range logic goes here
      // case 'custom':
      //   break;
      default:
        break;
    }
  };

  return (
    <div className='custom-select'>
      <Select
        name='dateRange'
        className='MuiSelect-root'
        value={selectedRange}
        onChange={handlePresetChange}
        MenuProps={{
          PaperProps: {
            sx: {
              boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
              marginTop: '-2px',
              borderRadius: '0 0 8px 8px',
              borderTop: '1px solid #EBEDF0'
            }
          }
        }}
        renderValue={(selected) => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              fontSize: '14px',
              height: '20px'
            }}
          >
            <img
              src={calendarIcon}
              alt='Calendar Icon'
              style={{ width: '20px', height: '20px', marginRight: '8px' }}
            />
            <span className='range-label'>
              {rangeLabels[selected as PresetType]}
            </span>
          </Box>
        )}
        IconComponent={ExpandMoreIcon}
      >
        {Object.keys(rangeLabels).map((key) => (
          <MenuItem key={key} value={key} sx={{ fontSize: '14px' }}>
            {rangeLabels[key as PresetType]}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default DateRangeSelector;
